import PropTypes from 'prop-types';
// @mui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useLocales from '../hooks/useLocales';
//

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4, 2),
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  description: PropTypes.string,
};

export default function EmptyContent({ title, description, ...other }) {
  const { translate } = useLocales();

  return (
    <RootStyle {...other}>
      <Typography variant="h6" gutterBottom >
        {title || translate("label.nodata")}
      </Typography>
      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </RootStyle>
  );
}
