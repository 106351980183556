/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Autocomplete, Chip, InputAdornment, Stack, TextField } from '@mui/material';
// components
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import InputStyle from '../../components/InputStyle';
import useLocales from '../../hooks/useLocales';
import { getTags, setTagSearch } from '../../redux/slices/hackerthon/hackerthon.tag';

// ----------------------------------------------------------------------
export default function ExerciseTableToolbar({ filterName, onFilterName, onChangeTags, selectedTags }) {
  const { translate } = useLocales();

  const dispatch = useDispatch();
  const { tags, search: searchTag, isLoading } = useSelector((state) => state.hackerthonTag);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getTags());
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchTag]);

  const handleFilterTags = (_, value) => {
    dispatch(setTagSearch({ ...searchTag, page: 0, value }));
  };

  return (
    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 2 }}>
      <InputStyle
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={translate('label.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
          endAdornment: (filterName?.trim().length > 0 &&
            <InputAdornment position="end">
              <Iconify icon={'ic:baseline-clear'} sx={{ color: 'text.disabled', width: 20, height: 20, cursor: 'pointer' }}
                onClick={() => onFilterName("")} />
            </InputAdornment>
          )
        }}
      />
      <Autocomplete
        sx={{ minWidth: { md: 300 } }}
        multiple
        onChange={(_, newValue) => onChangeTags(newValue)}
        options={tags?.filter(t => t.type !== 'LEVEL').map(({ id, title, type, exerciseNo }) => ({ id, title, type, exerciseNo }))}
        getOptionLabel={(option) => `${option.title} (${option.exerciseNo})` || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={isLoading}
        value={selectedTags || []}
        onInputChange={handleFilterTags}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip {...getTagProps({ index })} key={option.id} size="small" label={`${option.title} (${option.exerciseNo})`} />
          ))
        }
        renderInput={(params) => (
          <TextField
            label={translate('hackerthon.exercise.topic')}
            {...params}
          />
        )}
      />
    </Stack>
  );
}
