// @mui
import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
//
import useLocales from '../../hooks/useLocales';
import EmptyContent from '../EmptyContent';
import { SkeletonTableItem } from '../skeleton/SkeletonItem';

// ----------------------------------------------------------------------

TableNoData.propTypes = {
  isNotFound: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  searchQuery: PropTypes.string,
  length: PropTypes.number
};

export default function TableNoData({ isNotFound, isLoading, error, searchQuery, length = 9 }) {
  let { translate } = useLocales();

  if (error?.code === "403")
    return <TableRow>
      <TableCell colSpan={length}>
        <EmptyContent
          title={translate("label.signinToJmaster")}
          sx={{
            '& span.MuiBox-root': { height: 120 },
          }}
        />
      </TableCell>
    </TableRow>;

  return (
    <>
      {isLoading && <TableRow>
        <TableCell colSpan={length}>
          <SkeletonTableItem />
        </TableCell>
      </TableRow>}
      {isNotFound && (
        <TableRow>
          <TableCell colSpan={length}>
            <EmptyContent
              title="Không tìm thấy dữ liệu"
              description={searchQuery}
              sx={{
                '& span.MuiBox-root': { height: 120 },
              }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
