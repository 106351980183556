// @mui
import { Skeleton, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export function SkeletonSolutionItem() {
  return (
    <Stack direction={"row"} my={1} spacing={2}>
      <Skeleton variant="circular" sx={{ width: 36, height: 36 }} />
      <Skeleton variant="rectangular" width="100%" sx={{ height: 70, borderRadius: 1 }} />
    </Stack>
  );
}

export function SkeletonTableItem() {
  return (
    <Stack direction={"row"} spacing={2}>
      <Skeleton variant="text" sx={{ flexGrow: 1 }} />
      <Skeleton variant="text" sx={{ flexGrow: 1 }} />
      <Skeleton variant="text" sx={{ flexGrow: 1 }} />
      <Skeleton variant="text" sx={{ flexGrow: 1 }} />
      <Skeleton variant="text" sx={{ flexGrow: 1 }} />
    </Stack>
  );
}

export function SkeletonCommentItem() {
  return (
    <Stack direction={"row"} my={1} spacing={2}>
      <Skeleton variant="circular" sx={{ width: 36, height: 36 }} />
      <Skeleton variant="rectangular" width="100%" sx={{ height: 70, borderRadius: 1 }} />
    </Stack>
  );
}