/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
// next
import {
  Card,
  Divider,
  Link,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  useTheme
} from '@mui/material';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import { getExercises, setExerciseSearch } from '../../redux/slices/hackerthon/hackerthon.exercise';
import { useDispatch, useSelector } from '../../redux/store';
// hooks

import NextLink from 'next/link';
import { TableNoData } from '../../components/table';
import useLocales from '../../hooks/useLocales';
import { getLevels } from '../../redux/slices/hackerthon/hackerthon.tag';
import { fPercent } from '../../utils/formatNumber';
import ExerciseTableHead from './ExerciseTableHead';
import ExerciseTableToolbar from './ExerciseTableToolbar';
// --------------------------------------------------------------------

export default function ExerciseList() {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const theme = useTheme();

  const { exercises, search, numberOfElements, error, totalElements, isLoading } = useSelector((state) => state.hackerthonExercise);
  const { levels } = useSelector((state) => state.hackerthonTag);

  useEffect(() => {
    dispatch(getLevels());
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getExercises());
    }, 500);

    return () => clearTimeout(timeout);
  }, [search]);

  const handleRequestSort = (property) => {
    const isAsc = search.orders[0].property === property && search.orders[0].order === 'asc';
    const order = isAsc ? 'desc' : 'asc';

    dispatch(
      setExerciseSearch({
        ...search,
        page: 0,
        orders: [
          {
            order,
            property,
          },
        ],
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setExerciseSearch({
        ...search,
        page: 0,
        size: parseInt(event.target.value, 10),
      })
    );
  };

  const handleChangePage = (_, page) => {
    dispatch(
      setExerciseSearch({
        ...search,
        page,
      })
    );
  };

  const handleFilterByName = (value) => {
    dispatch(
      setExerciseSearch({
        ...search,
        page: 0,
        value,
      })
    );
  };

  const handleFilterByTags = (tags) => {
    const tagIds = tags?.map(t => t.id).toString();
    dispatch(setExerciseSearch({ ...search, page: 0, tags, filterBys: { ...search.filterBys, tagIds } }));
  };

  const handleFilterByLevel = (_, levelId) => {
    if (levelId) {
      dispatch(setExerciseSearch({ ...search, page: 0, filterBys: { ...search.filterBys, levelId } }));
    } else
      dispatch(setExerciseSearch({ ...search, page: 0, filterBys: { ...search.filterBys, levelId: null } }));
  };

  const COLORS = ['default', 'primary', 'warning', 'error', 'secondary', 'info', 'success'];
  let TABS = [{ value: null, label: 'Tất cả', color: COLORS[0], count: levels?.reduce((partialSum, row) => partialSum + row.exerciseNo, 0) }].concat(
    levels?.map(({ id, title, exerciseNo }, index) => ({ value: id, label: title, color: COLORS[index + 1] || COLORS[0], count: exerciseNo }))
  );

  const TABLE_HEAD = [
    { id: 'id', label: translate('hackerthon.exercise.id'), alignRight: false, sort: true },
    { id: 'title', label: translate('hackerthon.exercise.title'), alignRight: false, sort: true },
    { id: 'level', label: translate('hackerthon.exercise.level'), alignRight: false, sort: false },
    { id: 'submission', label: translate('hackerthon.exercise.submission'), alignRight: false, sort: false },
    { id: 'rate', label: translate('hackerthon.exercise.rate'), alignRight: false, sort: false },
  ];

  return (
    <Card sx={{ my: 3 }}>
      <Tabs
        allowScrollButtonsMobile={true}
        variant="scrollable"
        scrollButtons={"auto"}
        value={search.filterBys?.levelId || null}
        onChange={handleFilterByLevel}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        {TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.label}
            value={tab.value}
            label={
              <Stack spacing={1} direction="row" alignItems="center">
                <div>{tab.label}</div> <Label color={tab.color}> {tab.count} </Label>
              </Stack>
            }
          />
        ))}
      </Tabs>
      <Divider />

      <ExerciseTableToolbar
        onFilterName={handleFilterByName}
        filterName={search.value}
        selectedTags={search.tags}
        onChangeTags={handleFilterByTags}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ExerciseTableHead
              order={search.orders[0].order}
              orderBy={search.orders[0].property}
              headLabel={TABLE_HEAD}
              rowCount={numberOfElements}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {exercises.map((row) => (
                <TableRow hover key={row.id} tabIndex={-1} >
                  <TableCell>
                    <NextLink href={`/exercises/${row.slug}`} passHref>
                      <Link href='#' variant='subtitle2' color={theme.palette.text.primary}>
                        #{row.id}
                      </Link>
                    </NextLink>
                  </TableCell>
                  <TableCell >
                    <NextLink href={`/exercises/${row.slug}`} passHref>
                      <Link href='#' variant='subtitle2' color={theme.palette.text.primary}>
                        {row.title}
                      </Link>
                    </NextLink>
                  </TableCell>
                  <TableCell>
                    <Label color={
                      (row.level.title === 'Dễ' && 'primary') ||
                      (row.level.title === 'Trung bình' && 'warning') ||
                      (row.level.title === 'Khó' && 'error') ||
                      'default'
                    } sx={{ mr: 1 }}>{row.level.title}</Label>
                  </TableCell>
                  <TableCell>{row.answerNo}</TableCell>
                  <TableCell>
                    <Typography variant='subtitle2'>
                      {fPercent(row.acceptedNo * 100 / row.answerNo)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
              <TableNoData
                isNotFound={numberOfElements === 0}
                isLoading={isLoading}
                error={error}
                length={TABLE_HEAD.length + 1}
                searchQuery={search.value}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalElements}
        rowsPerPage={search.size}
        page={search.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card >
  );
}